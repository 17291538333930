.dashboard-entire-page {
    display: flex;

}

.dashboard-left-container {
    flex-basis: 70%;
}

.dashboard-right-container {
    flex-basis: 30%;

    text-align: center;
}

.page-main-header {
    font-size: 48px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
}

.page-small-title {
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 25px;
    border-bottom: 1px darkgray solid;
    margin-bottom: 15px;
}

.each-portfolio-container {
    /* border: 1px solid darkgray; */
    padding-top: 20px;
    padding-bottom: 10px;
}

.navlink-portfolio:hover {
    color: #f0b90b;
}

#portfolio-name-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    padding-bottom: 5px;
}

#payment-method-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    padding-bottom: 5px;
    margin-right: 20px;
}

#buying-power-and-type-text {
    font-weight: 700;
}

.trade-button-dashboard {
    background-color: #f0b90b;
    border: none;
    font-size: 18px;
    color: #1E2026;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.trade-button-dashboard:hover {
    background-color: #f0bb0ba7;
}

.my-table-td {
    padding: 15px;
}

.my-table {
    padding-top: 30px;
}

#coin-description-text {
    color: rgba(186, 186, 186, 0.857);
    font-size: 14px;
}

.each-payment-method-container {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
}

.deposits-entire-container {
    margin-top: 220px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;

    border: 2px solid darkgray;
    background-color: black;
}

.deposit-button {
    background-color: #5841D8;
    border: 1px solid #5841D8;
    color: white;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;

}

.deposit-button:hover {
    background-color: #5841d8c3;
}

.deposits-header-text {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.87);
    font-size: 20px;
    color: lightgray;
}

.deposits-description-text {
    color: rgba(255, 255, 255, 0.60);
    font-size: 12px;
    margin-bottom: 20px;
}



/* modals */
.modal-entire-container {
    border: 1px solid lightgray;
    background-color: rgb(231, 231, 231);
    color: black;
    width: 60vw;
    height: auto;
    text-align: center;
}

.modal-header-text {
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    font-size: 48px;
    font-weight: bold;
}

.modal-form-entire {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-input-text {
    font-weight: 600;
    margin-top: 10px;
}

.form-input {
    padding: 5px;
}

.form-submit-button {
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #f0b90b;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.form-submit-button:hover {
    background-color: #f0bb0ba7;
}


.watchlists-entire-container {
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;

    border: 2px solid darkgray;
    background-color: black;

}

.each-watchlist-name {
    padding: 10px 5px;
    /* display: flex; */
    display: inline;
    justify-content: space-around;
    font-size: 18px;

}

.watchlist-navlink:hover {
    color: #f0b90b;
}

.watchlists-header-text {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.87);
    font-size: 20px;
    color: lightgray;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.watchlist-row {
    padding-top: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid gray;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

}

.watchlist-row:last-child {
    border-bottom: none;
}
