/* frontend/src/context/Modal.css */
#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  #modal-content {
    position: absolute;
    background-color:white;
    border-radius: 10px;
    color: black;
    /* padding: 0 10px 0; */
  }
