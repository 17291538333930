.coin-detailed-data-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
}

.detail-inner-container {
    margin-right: 30px;
}

.detail-title {
    color: rgba(128, 128, 128, 0.563);
}

.price-change-positive {
    color: #02C076;
}

.price-change-negative {
    color: #F84960;
}

.coin-details-chart-container {
    display: flex;
    justify-content: center;
}

.coin-details-chart-chart {
    width: 80%;
}

.coin-details-chart-chart-modal-true {
    z-index: -1;
}

.view-coin-button {
    background-color: #F0B90B;
    border: none;
    color: #1E2026;
    font-weight: 600;

    border-radius: 5px;
    padding: 3px 6px;
}

.view-coin-button:hover {
    background-color: #f0bb0bbb;
}

.chart-time-intervals-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 20px;
}

.chart-time-each-interval {
    cursor: pointer;
    padding: 10px 5px;
}

/* .chart-time-each-interval:hover {
    color: #F0B90B;
} */

.chart-time-each-interval-current {
    padding: 10px 5px;
    background-color: #2B2f36;
}
