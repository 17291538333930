.homepage-entire-container {
    width: 100%;
    background-size: cover;
    /* background-image: url('../../assets/binanceBackgroundImg.png'); */

    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-center-homepage-text-container {
    text-align: center;
    margin-top: 100px;
}

.homepage-title-text {
    font-size: 60px;
    color: white;
}

#homepage-logo-text {
    color: #F0B90B;
    font-weight: 700;
    margin-bottom: 20px;
}

#homepage-trade-text {
    margin-top: 20px;
    margin-bottom: 20px;
}

#homepage-signup-button {
    color: #1E2026;
    font-weight: 600;
    background-color: #F0B90B;
    height: 80px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 30px;
    border-radius: 5px;
    font-size: 20px;
}

#homepage-signup-button:hover {
    background-color: #cda830;
}

.second-center-homepage-container {
    margin-top: 80px;
    text-align: center;
    font-size: 20px;
    background-color: black;
    padding: 20px;
}

.developer-logo-img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
}

.warning-text-homepage {
    font-size: 11px;
    color: gray;
    width: 70%;
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}


.all-coins-market-container {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    background-color: #fafafa;
    color: black;

    margin-top: 50px;
    margin-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10px;
    border-radius: 20px;
}

.home-page-table {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    /* need this line for border */
    border-collapse: collapse;
    padding-top: auto;
    padding-bottom: auto;
}


.table-homepage-th {
    padding-bottom: 10px;
    font-size: 12px;
    color: #848e9c;
    font-weight: 400;
}

.table-homepage-td {
    padding-bottom: 20px;
    padding-top: 20px;
}

.table-homepage-tr {
    border: solid 1px lightgray;
    border-left: none;
    border-right: none;
}

tr:first-child {
    border-top: none;
}

tr:last-child {
    border-bottom: none;
}

#homepage-table-coin-symbol {
    font-weight: 700;
}

.homepage-price-change-positive {
    color: #02c076;
}

.homepage-price-change-negative {
    color: #f84960;
}

#toast-small-text {
    font-size: 14px;
    font-style: italic;
}
