.remove-from-watchlist-button {
    background-color: #d9534f;
    padding: 3px 6px;
    border: none;
    color: #fff;
}

.watchlist-settings-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.edit-watchlist-button {
    background-color: lightgray;
    color: #1e2026;
    border: 1px solid gray;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 6px;

}

.edit-watchlist-button:hover {
    border: 1px solid #f0b90b;
}

.delete-watchlist-button {
    background-color: rgb(226, 88, 88);
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 6px;
}

.delete-watchlist-button:hover {
    background-color: #CF6679;
}

.show-details-button-container {
    display: inline;

}

.show-details-button {
    padding: 2px 20%;
    background-color: black;
    color: white;
    border: none;
}

.show-details-button:hover {
    color: #f0b90b;
}

.watchlist-coins-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.watchlist-each-coin-container {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;

    display: flex;
    justify-content: space-evenly;
}

.watchlist-coin-name-price {
    color: lightgray;
}


.watchlist-coins-table-container {
    display: flex;
    justify-content: space-evenly;
    width: 80%;

    margin-top: 20px;
    margin-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10px;
    border-radius: 20px;
}
