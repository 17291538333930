/* TODO Add site wide styles */
/* * {
    background-color: #121212;
    color: rgba(255, 255, 255, 0.87);
} */
a {
    color: rgba(255,255,255,0.87);
}

button {
    cursor: pointer;
}

* {
    font-family: 'Metrophobic', sans-serif;
}
